import React, { useMemo, useState } from "react"
import { arrayOf, func, number, shape, string } from "prop-types"
import { sortBy } from "lodash"
import { styled } from "@linaria/react"

import {
  Modal,
  Body,
  Footer,
  Header,
  Button
} from "src/features/meetingPacks/MeetingPack/Transcript/View/Modal"

import { buildSelectStyles, SearchableSelect } from "./styles"

const defaultAgendaItemOption = {
  value: null,
  label: "Select agenda item"
}

const AssignAgendaItemModal = ({
  transcriptAgendaItems,
  handleAssignAgendaItem,
  close,
  className
}) => {
  const agendaItemsOptions = useMemo(
    () =>
      sortBy(transcriptAgendaItems, ["agendaItemsListIndex"]).map((item) => ({
        value: item.agendaItemId,
        label: `${item.agendaItemsListIndex}. ${item.name}`
      })),
    [transcriptAgendaItems]
  )

  const [selectedAgendaItem, setSelectedAgendaItem] = useState()

  const handleSubmit = () => handleAssignAgendaItem(selectedAgendaItem?.value).then(close)

  return (
    <Modal className={className} size="lg" show onHide={close}>
      <Header>Assign to agenda item</Header>
      <Body>
        <SearchableSelect
          options={[defaultAgendaItemOption, ...agendaItemsOptions]}
          selected={selectedAgendaItem || defaultAgendaItemOption}
          setSelected={setSelectedAgendaItem}
          styles={buildSelectStyles()}
        />
      </Body>
      <Footer>
        <Button
          className="btn"
          width="md"
          onClick={handleSubmit}
          disabled={!selectedAgendaItem?.value}
        >
          Submit
        </Button>
      </Footer>
    </Modal>
  )
}

AssignAgendaItemModal.propTypes = {
  transcriptAgendaItems: arrayOf(
    shape({
      agendaItemId: number.isRequired,
      agendaItemsListIndex: string.isRequired,
      name: string.isRequired
    })
  ).isRequired,
  handleAssignAgendaItem: func.isRequired,
  close: func.isRequired
}

export default styled(AssignAgendaItemModal)`
  .dropdown button,
  .dropdown-menu {
    width: 100%;
    max-width: none;
  }
`
