import { styled } from "@linaria/react"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const ContentContainer = styled.div`
  height: auto;
`

export const ScrollContainer = styled.div`
  height: calc(100vh - 96px);
  overflow: auto;
`
