import { styled } from "@linaria/react"

import { BASE_COLORS } from "src/styles/colors"

export const TimelineContainer = styled.div`
  position: absolute;
  top: 0;
  right: calc(100% + 10px);
  display: flex;
  align-items: center;
  height: 100%;
  width: 75px;
  flex-direction: column;
  justify-content: center;
`

export const UtteranceStart = styled.div`
  position: relative;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;

  &.timeline-muted {
    color: ${BASE_COLORS.lightGray};
  }
`
