import { styled } from "@linaria/react"

import LinkButton from "src/components/LinkButton"
import { FONT_WEIGHTS } from "src/styles/sizes"

export const Title = styled.span`
  margin-right: 1rem;
`

export const AddFromOtherMeetingsButton = styled(LinkButton)`
  font-weight: ${FONT_WEIGHTS.semiBold};
`
