import { styled } from "@linaria/react"

import SecondaryButton from "src/styles/components/Button/Secondary"

export const Container = styled.div`
  grid-template-columns: 200px 1fr 200px;
`

export const BackButton = styled(SecondaryButton)`
  &::before {
    content: "←";
  }

  span {
    margin-left: 0.5rem;
  }
`
