import { styled } from "@linaria/react"

export const Title = styled.h6`
  margin-block: 8px;
  font-family: "Space Grotesk";
  color: #181023;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.3;
`
