import { styled } from "@linaria/react"

export const Textarea = styled.textarea`
  border: 1px solid #e7ecf3;
  background-color: #f4f5f9;
  outline: none;
  padding: 8px 12px;
  border-radius: 8px;
  min-height: 40px;
  overflow: hidden;
  color: #46404f !important; // override "important" color from "input.sass"
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  box-sizing: border-box;
  resize: none;
`
