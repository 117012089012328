import { styled } from "@linaria/react"
import { Modal } from "react-bootstrap"

import PrimaryButton from "src/styles/components/Button/Primary"

export const ModalFooter = styled(Modal.Footer)`
  display: flex;
  flex-direction: column;
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
`

export const ConfirmButton = styled(PrimaryButton)`
  margin-right: 1rem;
`
