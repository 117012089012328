import { styled } from "@linaria/react"
import { Col, Image } from "react-bootstrap"

import { SPACES } from "src/styles/sizes"
import { BACKGROUND_COLORS } from "src/styles/colors"
import { withVariations } from "src/helpers/linaria"

export const UserCardContainer = styled(Col)`
  margin-top: ${SPACES.small};
  cursor: pointer;
  display: flex;
  align-items: center;
  flex: 0 0 25%;
  min-width: 16rem;
`

export const AvatarImage = styled(Image)`
  width: 100%;
  min-height: 100%;
  object-fit: cover;
`

export const UserName = styled.span`
  &:hover {
    cursor: pointer;
  }
`

export const UserAvatarContainer = styled.div`
  &:hover {
    cursor: pointer;
  }
`

export const UserInitials = styled(withVariations("div", "variant", "size"))`
  margin-right: 0;

  &--size-small.round-toggle.initials {
    width: 24px;
    height: 24px;
    min-width: 24px;
    font-size: 0;
  }

  &--size-medium.round-toggle.initials {
    width: 32px;
    height: 32px;
    min-width: 32px;
  }

  &--variant-removed {
    border: solid 2px ${BACKGROUND_COLORS.danger} !important;
  }

  &--variant-added {
    border: solid 2px ${BACKGROUND_COLORS.success} !important;
  }
`
