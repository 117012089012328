import { styled } from "@linaria/react"

import { BASE_COLORS } from "src/styles/colors"

export const Container = styled.div`
  font-size: 0.85rem;
  margin: 0.5rem 0 1rem;
`

export const SuccessWarning = styled.div`
  color: ${BASE_COLORS.green};
`

export const DangerWarning = styled.div`
  color: ${BASE_COLORS.red};
`
