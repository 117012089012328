import { styled } from "@linaria/react"
import { Modal } from "react-bootstrap"

import LinkButton from "src/styles/components/Button/Link"
import { BASE_COLORS } from "src/styles/colors"

export const ModalBody = styled(Modal.Body)`
  input {
    padding: 0.5rem 0.8rem;
  }
`

export const Container = styled.div`
  margin-bottom: 1rem;
`

export const RowContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`

export const Column = styled.div`
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;

  @media (min-width: 992px) {
    flex: 0 0 33%;
    max-width: 33%;
  }
`

export const RemoveLink = styled(LinkButton)`
  color: ${BASE_COLORS.red};
  margin: 0.25rem 0 0;
`
