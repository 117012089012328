import { css } from "@linaria/core"
import { styled } from "@linaria/react"

import BaseModal, {
  Body as BaseBody,
  Footer as BaseFooter,
  Header as BaseHeader
} from "src/styles/components/Modal"

export const Modal = styled(BaseModal)`
  .modal-content {
    border-radius: 12px;
  }
`

export const Body = styled(BaseBody)`
  margin: 0;
  padding: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  color: #181023;
`

export const Button = styled.button`
  outline: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.3;
  font-family: "Space Grotesk";
  border: 1px solid #d7dee8;
  border-radius: 8px;
  padding: 10px;
  background: #f1f4f9;
  color: #181023;

  &:focus,
  &:active {
    color: #7e90a9;
  }
`

export const backdropClassName = css`
  background-color: #18102333;
  backdrop-filter: blur(4px);

  &.modal-backdrop.fade,
  &.modal-backdrop.show {
    opacity: 1;
  }
`

export const Footer = styled(BaseFooter)`
  gap: 10px;
`

export const Header = styled(BaseHeader)`
  font-family: "Space Grotesk";
  font-size: 16px;
  font-weight: 500;
  line-height: 1.3;
  color: #181023;
  padding: 16px;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;

  .close {
    font-weight: 100;
  }
`
