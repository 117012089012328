import React from "react"
import { shape, number, string, func, bool } from "prop-types"
import { styled } from "@linaria/react"
import { cx } from "@linaria/core"

import BaseUserAvatar from "src/components/UsersContacts/UserAvatar"

const UserAvatar = styled(BaseUserAvatar)`
  margin-right: 0.7rem;
`

const UserCard = ({ user, onChange, onDeselect, renderForCard, selected = false }) => {
  return (
    <div className={cx("card horizontal", selected ? "is-selected" : null)}>
      <div className="card-mascot">
        <UserAvatar user={user} />
      </div>
      <div className="card-title">
        <h6 className="card-title-primary">{user.fullName}</h6>
        {user.jobTitle && <div className="card-title-secondary">{user.jobTitle}</div>}
      </div>
      <div className="card-controls">
        {renderForCard({ entity: user, entityType: "user", selected, onChange })}
        {selected && (
          <div className="checkbox">
            <input
              type="checkbox"
              id={`member-pick-checkbox-user-${user.id}`}
              defaultChecked={selected}
              onClick={onDeselect}
              data-behavior="member-pick-checkbox"
              data-type="user"
              data-id={user.id}
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor={`member-pick-checkbox-user-${user.id}`} />
          </div>
        )}
      </div>
    </div>
  )
}

UserCard.propTypes = {
  user: shape({
    id: number.isRequired,
    email: string.isRequired,
    initials: string.isRequired,
    avatarColorId: number.isRequired,
    avatarUrl: string,
    fullName: string.isRequired,
    jobTitle: string
  }).isRequired,
  onChange: func.isRequired,
  renderForCard: func.isRequired,
  onDeselect: func,
  selected: bool
}

export default UserCard
