import { styled } from "@linaria/react"

import SectionTitle from "../SectionTitle"

export const Title = styled(SectionTitle)`
  margin-top: 8px;
  margin-bottom: 16px;
`

export const TranscriptContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  position: relative;
  margin-right: 20px;
  margin-left: 40px;
`

export const ScrollWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  height: 0;
  flex-direction: column;
  padding: 15px 10px;
  border: 1px solid #e7ecf3;
  border-radius: 12px;
  background-color: white;
  position: relative;
`

export const UtterancesContainer = styled.div`
  overflow: auto;
  flex: 1 1 auto;
  padding-right: 20px;
`
