import { styled } from "@linaria/react"
import { Form } from "react-bootstrap"

import { BASE_COLORS, TEXT_COLORS } from "src/styles/colors"

export const selectStyles = {
  control: (styles) => ({ ...styles, padding: "0 0.75rem" }),
  placeholder: (styles) => ({
    ...styles,
    color: BASE_COLORS.darkBlue
  })
}

export const invalidSelectStyles = {
  ...selectStyles,
  control: (styles) => ({
    ...selectStyles.control(styles),
    borderColor: TEXT_COLORS.danger
  })
}

export const Label = styled(Form.Label)`
  color: ${TEXT_COLORS.muted};
  font-size: 0.85rem;
`
