import { styled } from "@linaria/react"

import LinkButton from "src/components/LinkButton"

export const AddNewPeopleBtn = styled(LinkButton)`
  margin-left: 1rem;
`

export const ModalTitle = styled.h5`
  display: inline;
`
