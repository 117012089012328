import { styled } from "@linaria/react"

import { BACKGROUND_COLORS } from "src/styles/colors"
import { SPACES, messageActorAvatar, FONT_SIZES } from "src/styles/sizes"
import BaseUserAvatar from "src/components/UsersContacts/UserAvatar/UserAvatar"
import AvatarImageContainer from "src/components/UsersContacts/AvatarImageContainer"
import { UserInitials } from "src/components/UsersContacts/styles"

export const MessageActorCardContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: ${SPACES.xxSmall};
  background: ${BACKGROUND_COLORS.main};
  padding: 0.4rem;
  height: 26px;
`

export const UserAvatar = styled(BaseUserAvatar)`
  margin-right: ${SPACES.xxSmall};
  width: ${messageActorAvatar};
  height: ${messageActorAvatar};

  ${AvatarImageContainer}, ${UserInitials} {
    width: ${messageActorAvatar};
    height: ${messageActorAvatar};
    min-width: ${messageActorAvatar};
  }

  ${UserInitials} {
    font-size: ${FONT_SIZES.xxSmall};
    padding: 0;
  }
`
