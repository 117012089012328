import { styled } from "@linaria/react"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 45%;
`

export const SubTitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 1.3;
  color: #7e90a9;
  margin-bottom: 16px;
`

export const List = styled.div`
  gap: 6px;
  display: grid;
  grid-template-columns: max-content 1fr min-content;

  &.inner-list {
    grid-column: 1/-1;
  }
`

export const ListContainer = styled.div`
  flex: 1 1 auto;
  overflow: auto;
  height: 0;
  padding-right: 20px;
`
